$form-floating-height: 4rem;
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.scss';

.modal-header {
	border-bottom: 0 none;
}

.modal-footer {
	border-top: 0 none;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.modal-footer > * {
	align-self: stretch;
}

.modal-content {
	border-radius: 1rem;
}

.modal-title {
	font-size: 2rem;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
	letter-spacing: 0px;
	text-align: center;
}

.form-label {
	margin-bottom: 0;
}

.carousel {
	max-width: 54rem;
	margin: auto;
}
.carousel-text {
	box-shadow: 0 0 20px 50px rgba(0, 0, 0, 0.7);
	background:
        /* top, transparent black, faked with gradient */ linear-gradient(
		rgba(0, 0, 0, 0.7),
		rgba(0, 0, 0, 0.7)
	);
}

.card-widget {
	min-width: 15rem;
	margin: 0.4rem;
}

.card-widget h2 {
	font-weight: bold;
	text-align: center;
}
